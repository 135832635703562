import * as types from './mutation-types'

const mutations = {
    [types.SET_TOKEN](state, token) {
        state.token = token
        localStorage.setItem('token', token);
        console.log("我设置了token" + token)
    },
    [types.SET_USER](state, user) {
        state.user = user
    },
    [types.SET_BASEURL](state, baseUrl) {
        state.baseUrl = baseUrl
    },
    [types.SET_STATUS](state, status) {
        state.status = status
    },
    [types.SET_TARGETPATH](state, targetPath) {
        state.targetPath = targetPath
    },
    [types.SET_OPENID](state, openid) {
        state.openid = openid
    },
    [types.SET_GETATIENTINFO](state, patientInfo) {
        state.patientInfo = patientInfo
    },
    [types.SET_ISWEIXIN](state, isWeixin) {
        state.isWeixin = isWeixin
    },
    [types.SET_GZHBASEURL](state, gzhBaseUrl) {
        state.gzhBaseUrl = gzhBaseUrl
    },

}
export default mutations