import * as types from './mutation-types'
// import { getToken } from 'utils/auth'
// import LoginApi from '@api/loginApi'

// 获取用户状态
// export const GetAccessToken = ({ commit }) => {
//     return new Promise((resolve, reject) => {
//         LoginApi.getAccessToken().then(response => {
//             // commit(types.SET_OPPID, response.data.oppid)
//             resolve(response.data)
//         }).catch(error => {
//             reject(error)
//         })
//     })
// }
export const getPatientInfo = ({ commit }, patientInfo) => commit('getPatientInfo', patientInfo)