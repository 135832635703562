import store from '../store'
/*
 或群url参数
 * name参数名
 * 
 * */
export function getUrlParam(name) {
    const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
    const r = window.location.search.substr(1).match(reg); //匹配目标参数
    if (r != null) return unescape(r[2]);
    return null; //返回参数值
}

/*
 * 判断路径是否已拼接
 * url 参数名需要匹配的路径
 * 
 * */
export function checkPortraitUrl(url) {
    const portrai = /http:/g.test(url) ? url : `http://api.doctordd.cn${url}`;
    return portrai
}

export function trim(str) {
    let strTrim = str.toString();
    return strTrim.replace(/(^\s*)|(\s*$)/g, "");
}
/*
 * 判断用户是否有登录
 * token 参数名需要匹配的路径
 * 
 * */
export function isLogin(that, path) {
    if (!store.getters.token) {
        that.$createDialog({
            type: 'confirm',
            title: '您暂未登录，点击跳转登录页面',
            onConfirm: () => {
                store.commit("SET_TARGETPATH", { path })
                that.$router.push({ path: '/login' })
            }
        }).show()
        return false
    } else {
        return true
    }

}

/*
 * 跳转登录页面
 * 
 * */
export function toLogin(that) {
    toPath(that);
}

/*
 * 跳转其他页面
 * path h5页面路径 空为/login
 * miniPath 跳转小程序具体页面路径 空为/pages/web/index/index
 * */
export function toPath(that, pathObj, miniPath, fnName = 'push') {
    // if (!pathObj.path && !miniPath) { //login
    //     miniPath = '/pages/mine/login/login';
    //     path = '/login'
    // } else if (!miniPath) { //mini
    //     // miniPath = '/pages/web/index/index';
    // }
    var miniprogram = false;
    // console.log('toPath', miniPath, pathObj.path)

    var ready = function() {
        miniprogram = window.__wxjs_environment === 'miniprogram';
        // console.log('miniprogram', miniprogram)
        if (miniprogram) { //跳到小程序页面
            const { query } = pathObj
            let queryStr = ''
            for (let k in query) {
                queryStr += `${k}=${query[k]}&`
            }
            queryStr = queryStr.replace(/&$/, '')
            const path = queryStr ? `${pathObj.path}?${queryStr}` : pathObj.path
            miniPath ? wx.miniProgram.reLaunch({
                url: miniPath
            }) : wx.miniProgram.navigateTo({
                url: '/pages/web/index/index' + '?path=' + encodeURIComponent(path)
            })

        } else {
            that.$router[fnName](pathObj)
        }
    };

    if (!window.WeixinJSBridge || !WeixinJSBridge.invoke) {
        document.addEventListener('WeixinJSBridgeReady', ready(), false)
    } else {
        ready();
    }

}


/*
 * 判断是否在微信端
 * 返回true or false
 * 
 * */
export function is_weixin() {
    var ua = window.navigator.userAgent.toLowerCase();
    return new Promise((resolve, reject) => {
        ua.match(/MicroMessenger/i) == 'micromessenger' ? resolve(true) : resolve(false)
    })
}

//判空
export function isEmpty(obj){
    if(typeof obj == "undefined" || obj == null || obj == ""){
        return true;
    }else{
        return false;
    }
}

//app登录回调返回token
export function hzzmLoginCallBack(token){

    localStorage.setItem('healthme_token', token)
    store.commit('SET_TOKEN', token);
}
