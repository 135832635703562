import router from './router'
import store from './store'
import { is_weixin, toPath, getUrlParam } from './utils/common'


// 路由开始过滤
// let first = true;
router.beforeEach((to, from, next) => {
    let path = to.path
    const { title } = to.meta
        // const { token } = to.query
    const token = to.query.token || to.query['dingding-token'] || localStorage.getItem('healthme_token') || store.getters.token || getUrlParam('dingding-token')
        // console.log("我是路由的拦截token5" + token)
    let allowAccess = [
        '/',
        '/login',
        '/numberLogon',
        '/register',
        '/searchIndex',
        '/clinicalTrialDeail',
		'/jumpLogin',
    ]
    is_weixin().then(res => {
        store.commit("SET_ISWEIXIN", res);
    })
    if (token) {
        // first = false;
        localStorage.setItem('healthme_token', token);
        store.commit("SET_TOKEN", token);
        if (getUrlParam('dingding-token')) localStorage.setItem('dingding-token', token)

    } else {
        if (allowAccess.some(item => item === path)) {
            // store.commit("SET_ISWEIXIN", res);
            // store.commit("SET_TARGETPATH", { path, query: to.query })
            next()
            return
        } else {
            return next({ path: '/login' })
        }


    }
    document.title = title
    next();
})

// 路由结束过滤
router.afterEach(() => {
    // store.commit('SET_LOADING', false)
})