import Vue from 'vue'
import Router from 'vue-router'
// 快速登录页面
const Login = () =>
    import ('@views/login')
    // 账号登录
const NumberLogon = () =>
    import ('@views/login/numberLogon')

// 注册页面
const Register = () =>
    import ('@views/login/register')

//这是一开始增加患者信息页面
const CreatePatient = () =>
    import ('@views/patient/createPatient')
    //这里是回答问题前的添加患者信息页面
const CrePatientBeforeAnswer = () =>
    import ('@views/patient/crePatientBeforeAnswer')
    //这里是上传病历后的添加患者信息页面
const CrePatientAfterUpload = () =>
    import ('@views/patient/crePatientAfterUpload')
    //更新患者信息页面
const UpdatePatient = () =>
    import ('@views/patient/updatePatient')
    //上传病历页面
const UploadMedicalRecord = () =>
    import ('@views/medicalRecord/uploadMedicalRecord')
    //更新病历页面
const UpdateMedicalRecord = () =>
    import ('@views/medicalRecord/updateMedicalRecord')
    //临川试验详情
const ClinicalTrialDeail = () =>
    import ('@views/clinicalTrial/clinicalTrialDeail')


const test = () =>
    import ('@views/test')
const mineIndex = () =>
    import ('@views/mine/mineIndex')
const mineJoin = () =>
    import ('@views/mine/mineJoin')
const mineFav = () =>
    import ('@views/mine/mineFav')
const patientMatchList = () =>
    import ('@views/patient/patientMatchList')
const patientList = () =>
    import ('@views/patient/patientList')
const patientInfo = () =>
    import ('@views/patient/patientInfo')

// 搜索模块
const SearchIndex = () =>
    import ('@views/search/searchIndex')

// 回答问题
const Question = () =>
    import ('@views/question/question')

const Apply = () =>
    import ('@views/testMatch/apply')

const SelectPatient = () =>
    import ('@views/patient/selectPatient')

const ApplySuccess = () =>
    import ('@views/testMatch/applySuccess')

const DySuccess = () =>
    import ('@views/testMatch/dySuccess')

// 首页
const HomeIndex = () =>
    import ('@views/home/index')
Vue.use(Router)
export default new Router({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            if (from.meta.keepAlive) {
                from.meta.savedPosition = document.body.scrollTop
            }
            return { x: 0, y: to.meta.savedPosition || 0 }
        }
    },
    routes: [{
            path: '/',
            name: 'HomeIndex',
            component: HomeIndex,
            meta: {
                title: '肿瘤免费用药患者招募', keepAlive: true
            }
        },
        {
            path: '/test',
            name: 'test',
            component: test,
            meta: {
                title: '主页'
            }
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            meta: { title: '快速登录' }
        },
        {
            path: '/numberLogon',
            name: 'NumberLogon',
            component: NumberLogon,
            meta: { title: '账号登录' }
        },
        {
            path: '/register',
            name: 'Register',
            component: Register,
            meta: { title: '注册' }
        },
        {
            path: '/createPatient',
            name: 'CreatePatient',
            component: CreatePatient,
            meta: {
                title: '患者信息'
            }
        },
        {
            path: '/crePatientBeforeAnswer',
            name: 'CrePatientBeforeAnswer',
            component: CrePatientBeforeAnswer,
            meta: {
                title: '患者基本信息'
            }
        },
        {
            path: '/crePatientAfterUpload',
            name: 'CrePatientAfterUpload',
            component: CrePatientAfterUpload,
            meta: {
                title: '完善患者信息'
            }
        },

        {
            path: '/updatePatient',
            name: 'UpdatePatient',
            component: UpdatePatient,
            meta: {
                title: '基本信息'
            }
        },
        {
            path: '/uploadMedicalRecord',
            name: 'UploadMedicalRecord',
            component: UploadMedicalRecord,
            meta: {
                title: '上传病历'
            }
        },
        {
            path: '/updateMedicalRecord',
            name: 'UpdateMedicalRecord',
            component: UpdateMedicalRecord,
            meta: {
                title: '更新病历'
            }
        },
        {
            path: '/clinicalTrialDeail',
            name: 'ClinicalTrialDeail',
            component: ClinicalTrialDeail,
            meta: {
                title: '临床试验详情'
            }
        },
        { // 搜索
            path: '/searchIndex',
            name: 'SearchIndex',
            component: SearchIndex,
            meta: {
                title: '搜索',
                keepAlive: true
            }
        },
        { // 回答问题
            path: '/question',
            name: 'Question',
            component: Question,
            meta: {
                title: '回答问题'
            }
        },
        {
            path: '/mineIndex',
            name: 'mineIndex',
            component: mineIndex,
            meta: {
                title: '我的'
            }
        },
        {
            path: '/mineJoin',
            name: 'mineJoin',
            component: mineJoin,
            meta: {
                title: '我报名的项目'
            }
        },
        {
            path: '/mineFav',
            name: 'mineFav',
            component: mineFav,
            meta: {
                title: '我收藏的项目'
            }
        },
        {
            path: '/apply',
            name: 'Apply',
            component: Apply,
            meta: {
                title: '填写报名信息'
            }
        },
        {
            path: '/selectPatient',
            name: 'SelectPatient',
            component: SelectPatient,
            meta: {
                title: '选择一位患者'
            }
        },
        {
            path: '/applySuccess',
            name: 'ApplySuccess',
            component: ApplySuccess,
            meta: {
                title: '报名成功'
            }
        },
        {
            path: '/dySuccess',
            name: 'DySuccess',
            component: DySuccess,
            meta: {
                title: '报名成功'
            }
        },
        {
            path: '/patientMatchList',
            name: 'patientMatchList',
            component: patientMatchList,
            meta: {
                title: '试验匹配'
            }
        },
        {
            path: '/patientList',
            name: 'patientList',
            component: patientList,
            meta: {
                title: '患者管理'
            }
        },
        {
            path: '/patientInfo',
            name: 'patientInfo',
            component: patientInfo,
            meta: {
                title: '患者病情信息'
            }
        },
		{
		    path: '/jumpLogin',
		    name: 'jumpLogin',
		    component: () => import ('@views/jumpLogin'),
		    meta: {
		        title: '注册'
		    }
		},
    ]
})