// import { getToken } from '@/utils/auth'

// const isLoggedIn = function() {
//     const cookie = getToken()
//     if (cookie) {
//         console.log(localStorage.getItem(cookie))
//         return localStorage.getItem(cookie)
//     } else {
//         return false
//     }
// }
const state = {
    token: localStorage.getItem("token") || null,
    user: {
        name: '',
        id: ''
    },
    targetPath: {
        path: '/'
    },
    baseUrl: 'http://api.healthme.cn',
    openid: localStorage.getItem("openid") || null,
    status: null,
    patientInfo: {},
    isWeixin: true,
    gzhBaseUrl: 'https://zhm.healthme.cn',
    // gzhBaseUrl: 'https://zhm.healthme.cn',

};

export default state;
