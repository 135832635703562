import Vue from 'vue'
import './cube-ui'
import App from './App.vue'
import router from './router'
import './permission' // 路由过滤 
//import VConsole from 'vconsole'
import store from './store'
import './registerServiceWorker'
import 'amfe-flexible/index.js'
//new VConsole()

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

// 封装调用客户端的方法
window.JSBridgeApp = function(funName, params) {
    const Nu = navigator.userAgent
    console.log('进入客户端方法调用')
    if (Nu.indexOf('Android') > -1 || Nu.indexOf('Adr') > -1) {
        console.log('我是安卓')
        window.WebContainer[funName](params);
    } else if (/(iPhone|iPad|iPod|iOS)/i.test(Nu)) {
        // console.log('我是ios')
        console.log('JSBridgeApp', (window.webkit && window.webkit.messageHandlers))
        // console.log('params', params)
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers[funName]) {
            console.log('我是ios')
            window.webkit.messageHandlers[funName].postMessage(params)
        }
    }
}
